<template>
  <form-view v-loading="loading" type="info">
    <div class="section-title-row">
      <div class="title">巡查基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label="工地名称" prop="siteName">
          <el-input disabled v-model="dataForm.siteName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="施工部位" prop="constructionPart">
          <el-input disabled v-model="dataForm.constructionPart"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label="工地地址" prop="siteAddress">
          <el-input disabled v-model="dataForm.siteAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="施工车辆" prop="number">
          <el-input disabled v-model="dataForm.number"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="8">
        <el-form-item label="车长" prop="carNickName">
          <el-input disabled v-model="dataForm.carNickName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="泵别" prop="emissionStandard">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="巡查时间" prop="createTime">
          <el-input disabled v-model="dataForm.createTime"></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <el-table
      :data="tableList"
      stripe
      size="mini"
      :border="true"
      :max-height="300"
      width="200px"
    >
      <el-table-column
        header-align="center"
        align="center"
        prop="dictName"
        label=" ">
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="patrolStatus"
        label="通过">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==1" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="不通过">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==2" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="不需要">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==3" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'

export default {
  name: 'dispatch-patrol-info',

  components: { FormView },

  data () {
    return {
      loading: false,
      dataForm: {},
      tableList: [],
      id: null
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      if (id) {
        this.loading = false
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/taskPatrol/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.tableList = data.datas.contentList
          this.loading = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /*.bracket-area {*/
  /*  .el-form-item__content {*/
  /*    white-space: nowrap;*/
  /*    .el-input {*/
  /*      width: 80%;*/
  /*    }*/
  /*    .el-input + label {*/
  /*      margin-left: 10px;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.flex-row {*/
  /*  margin: 20px 0;*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  .el-select,*/
  /*  .el-date-editor {*/
  /*    width: 150px;*/
  /*    flex: 0 0 auto;*/
  /*  }*/
  /*  label {*/
  /*    margin: 0 10px;*/
  /*    white-space: nowrap;*/
  /*  }*/
  /*  .el-input.remark {*/
  /*    flex: 1;*/
  /*    width: auto;*/
  /*  }*/
  /*  .icon {*/
  /*    font-size: 20px;*/
  /*    margin-left: 10px;*/
  /*    cursor: pointer;*/
  /*    color: #666;*/
  /*    &:hover {*/
  /*      color: #999;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.license-area {*/
  /*  margin-bottom: 20px;*/
  /*  display: flex;*/
  /*  justify-content: space-around;*/
  /*}*/
  .icon-warp{
    font-size: 20px;
  }
</style>
