<template>
  <div class="default-form-view scrollbar">
    <el-form :model="dataForm" ref="dataForm" label-position="left" :label-width="labelWidth + 'px'" status-icon>
      <slot></slot>
      <el-form-item class="button-row">
        <el-button type="primary" @click="edit" v-if="type === 'info' && !noEdit">编辑</el-button>
        <el-button type="primary" @click="submit" v-if="type === 'update' || type === 'assign'">保存</el-button>
        <el-button type="success" @click="approve" v-if="type === 'verify'">审核通过</el-button>
        <el-button type="danger" @click="openRemarkModal" v-if="type === 'verify' && abortable">审核不通过</el-button>
        <el-button type="warning" @click="cancel">{{ type === 'info' ? '返回' : '取消' }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import emitter from '../../utils/mixins/emitter.js'

export default {
  name: 'form-view',

  mixins: [emitter],

  props: {
    labelWidth: {
      type: Number,
      default: 70
    },
    url: String,
    dataForm: Object,
    beforeSubmit: Function,
    customValidator: Function,
    customSubmit: Function,
    type: {
      type: String,
      default: 'update',
      validator (v) {
        return ['update', 'info', 'verify', 'assign'].includes(v)
      }
    },
    noEdit: {
      type: Boolean,
      default: false
    },
    abortable: {
      type: Boolean,
      default: false
    },
    abortMethod: Function,
    verifyMethod: Function,
    successRoute: String
  },

  methods: {
    submit () {
      if (this.customValidator && !this.customValidator()) return
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          if (this.customSubmit) {
            return this.customSubmit(this.dataForm)
          }
          const loadingObj = this.createLoadingObj()
          const params = this.beforeSubmit ? this.beforeSubmit(this.dataForm) : this.dataForm
          this.handleReq(this.$http({
            url: this.$http.adornUrl(this.url),
            method: 'post',
            data: this.$http.adornData(params)
          }), loadingObj)
        }
      })
    },

    createLoadingObj () {
      return this.$loading({
        lock: true,
        text: '数据正在提交，请稍等.',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
    },

    handleReq (req, loadingObj) {
      req.then(data => {
        if (data && data.code === 200) {
          loadingObj.close()
          this.$opts.success()
          this.backToList()
        } else {
          loadingObj.close()
        }
      }).catch(() => {
        loadingObj.close()
      })
    },

    edit () {
      this.$router.push({ name: this.$route.name.split('-info')[0] + '-update', query: { id: this.$route.query.id } })
    },

    openRemarkModal () {
      this.$prompt('请输入不通过的原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.confirmAbort(value)
      }).catch(() => {

      })
    },

    confirmAbort (value) {
      const loadingObj = this.createLoadingObj()
      this.handleReq(this.abortMethod(value), loadingObj)
    },

    approve () {
      const loadingObj = this.createLoadingObj()
      this.handleReq(this.verifyMethod(), loadingObj)
    },

    backToList () {
      if (this.successRoute) {
        return this.$router.push({ name: this.successRoute })
      }
      switch (this.type) {
        case 'update':
          this.$router.push({ name: this.$route.name.split('-update')[0] })
          break
        case 'verify':
          this.$router.push({ name: this.$route.name.split('-verify')[0] })
          break
        case 'info':
          this.$router.push({ name: this.$route.name.split('-info')[0] })
          break
        case 'assign':
          this.$router.push({ name: this.$route.name.split('-assign')[0] })
          break
        default:
          break
      }
    },

    cancel () {
      if (this.type === 'update') {
        this.$confirm('确认取消编辑吗？未保存的更改将被重置', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.backToList()
        }).catch(() => { })
      } else {
        this.backToList()
      }
    }
  }
}
</script>

<style lang="scss">
.default-form-view {
  padding: 30px 50px 20px;
  height: 100%;
  position: relative;
  .el-form {
    .el-form-item {
      margin-bottom: 10px;
    }
    .el-form-item.button-row {
      width: 100%;
      text-align: center;
      margin-right: 0;
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .el-list-enter-active,
  .el-list-leave-active {
    transition: none;
  }
}
</style>
